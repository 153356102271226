import { Tooltip, TooltipProps, Text } from "@chakra-ui/react";

interface ReadOnlyTooltipProps extends TooltipProps {
  readOnly: boolean;
}
export default function ReadOnlyTooltip(props: ReadOnlyTooltipProps) {
  const { children, ...rest } = props;

  return (
    <Tooltip
      hasArrow
      isDisabled={!rest.readOnly}
      label={
        <>
          <Text>Operating in read-only mode</Text>
        </>
      }
      {...rest}
    >
      {children}
    </Tooltip>
  );
}
