import { useState } from "react";
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import { ArrowDropDown, Visibility } from "@material-ui/icons";
import { useParams } from "react-router";
import { EndpointApi } from "svix/dist/openapi";

import { getApiError } from "@svix/common/utils";
import ConfirmationDialog from "@svix/common/widgets/ConfirmationDialog";
import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";
import Stat from "@svix/common/widgets/Stat";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";
import { useLoadingManual } from "src/utils";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";

export default function SigningSecret() {
  const { endpId } = useParams<{ endpId: string }>();
  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);
  const user = useAppSelector((state) => state.auth.user)!;
  const [isShowingRotateDialog, setShowingRotateDialog] = useBoolean();
  const toast = useToast();
  const [signingSecret, setSigningSecret] = useState<string>();

  const [loadingSecret, , getSecret] = useLoadingManual(async () => {
    const api = getSvix();
    const ret = await api.endpoint.getSecret(user.app.id, endpId);
    setSigningSecret(ret.key);
  }, [endpId]);

  const [, , rotateSecret] = useLoadingManual(async () => {
    const sv = getSvix();
    const config = sv._configuration;
    const endpApi = new EndpointApi(config);

    try {
      await endpApi.v1EndpointRotateSecret({
        endpointId: endpId,
        appId: user.app.id,
        endpointSecretRotateIn: {},
      });
      setShowingRotateDialog.off();
      setSigningSecret(undefined);
    } catch (err) {
      const errorMsg = getApiError(err);

      toast({
        title: "Failed to rotate secret",
        description: errorMsg,
      });
    }
  }, [endpId]);

  return (
    <Stat
      id="stat-signing-secret"
      name="Signing Secret"
      cta={
        <Menu placement="bottom">
          <MenuButton
            ml={2}
            size="xs"
            as={IconButton}
            variant="rounded"
            data-cy="options-button"
          >
            <ArrowDropDown fontSize="small" />
          </MenuButton>
          <MenuList data-cy="options-menu">
            <ReadOnlyTooltip readOnly={isReadOnly}>
              <MenuItem onClick={setShowingRotateDialog.on}>Rotate secret...</MenuItem>
            </ReadOnlyTooltip>
          </MenuList>
        </Menu>
      }
    >
      <span style={{ fontVariant: "proportional-nums" }}>
        {signingSecret ? (
          <Text as="span" fontFamily="mono">
            {signingSecret}
          </Text>
        ) : loadingSecret ? (
          <LoadingIndicator size="sm" thickness="4px" />
        ) : (
          <>
            <Text as="span" letterSpacing={1}>
              &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
            </Text>
            <IconButton
              ml={2}
              size="small"
              onClick={getSecret}
              aria-label="Click to reveal"
              variant="ghost"
            >
              <Visibility />
            </IconButton>
          </>
        )}
      </span>
      <ConfirmationDialog
        colorScheme="red"
        title="Rotate Secret?"
        isOpen={isShowingRotateDialog}
        onCancel={setShowingRotateDialog.off}
        onOk={rotateSecret}
        labelOk="Rotate"
      >
        <Text>Are you sure you would like to rotate the signing secret?</Text>
        <Text mt={2}>
          Rotating the secret will <strong>expire it</strong> and{" "}
          <strong>replace it</strong> with a new one. The old one will only be valid for
          the next 24 hours. Afterward, trying to verify with the old secret will fail.
        </Text>
      </ConfirmationDialog>
    </Stat>
  );
}
