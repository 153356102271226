import { useCallback, useEffect, useState } from "react";
import { TransformationTemplateApi } from "svix/dist/openapi";

import * as C from "@svix/common/constants";
import { logError } from "@svix/common/logger";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { getPopupWindowFeatures } from "./utils";

export const useSlackOauth = (onIncomingWebhookReceived: (url: string) => void) => {
  const [isLoading, setIsLoading] = useState(false);
  const [externalPopup, setExternalPopup] = useState<Window | null>(null);
  const [oauthError, setOauthError] = useState<string | null>(null);

  const redirectUri = `https://redirectmeto.com/${
    window.location.origin
  }${routeResolver.getRoute("oauth.slack.authorize")}`;

  const fetchToken = useCallback(
    async (code: string) => {
      if (isLoading) {
        return;
      }

      try {
        setIsLoading(true);
        const svix = getSvix();
        const api = new TransformationTemplateApi(svix._configuration);
        const res = await api.v1TransformationTemplateOauthSlack({
          oAuthPayloadIn: {
            code: code,
            redirectUri: redirectUri,
          },
        });
        setIsLoading(false);

        if (!res.incomingWebhookUrl || res.error) {
          logError("Failed to connect to Slack", res.error ?? undefined);
          setOauthError("Failed to connect to Slack. Please try again.");
          return;
        }
        onIncomingWebhookReceived(res.incomingWebhookUrl);
      } catch (err) {
        logError("Failed to connect to Slack", err);
        setOauthError("Failed to connect to Slack. Please try again.");
        setIsLoading(false);
      }
    },
    [isLoading, redirectUri, setOauthError, onIncomingWebhookReceived]
  );

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }

        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("code");
        const authorizeError = searchParams.get("error");

        if (code || authorizeError) {
          if (code) {
            fetchToken(code);
          }

          if (authorizeError) {
            setOauthError("Failed to connect to Slack. Please try again.");
          }
          externalPopup.close();
          setExternalPopup(null);
          clearInterval(timer);
        }
      } catch (e) {
        // Ignore error. Keep polling until user is redirected.
      }
    }, 100);
  }, [externalPopup, fetchToken]);

  const onConnect = useCallback(() => {
    const popup = window.open(
      C.getSlackAppPortalOauthAuthorizeUrl(redirectUri),
      "popup-slack",
      getPopupWindowFeatures()
    );
    setExternalPopup(popup);
  }, [redirectUri]);

  return {
    onConnect,
    isLoading,
    error: oauthError,
  };
};
