import { lazy, useState, useEffect, Suspense } from "react";
import {
  Box,
  Text,
  Stack,
  HStack,
  FormLabel,
  Button,
  Alert,
  AlertIcon,
  Skeleton,
} from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { useQuery } from "react-query";
import { EventExampleIn, Svix } from "svix";
import { EndpointApi, TransformationTemplateApi } from "svix/dist/openapi";

import { Lang } from "./form/CodeEditor/Lang";
import Select from "./form/Select";
import { generateSampleCodeForSchema } from "./JsonSchema/SchemaPreviewer/utils";

const CodeEditor = lazy(() => import("./form/CodeEditor"));
interface TestProps {
  getSvix: () => Promise<Svix> | Svix;
  code: string;
  appId?: string;
  endpointId?: string;
}

export default function TestTransformation(props: TestProps) {
  const { endpointId, appId, getSvix, code } = props;
  const [testPayload, setTestPayload] = useState("{}");
  const [testOutput, setTestOutput] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  const [outputUrl, setOutputUrl] = useState<string>();
  const [outputMethod, setOutputMethod] = useState<string>();

  const formCtx = useForm<EventExampleIn>();

  const selectedEventTypeName = useWatch({
    control: formCtx.control,
    name: "eventType",
    defaultValue: "custom",
  });

  const { data: eventTypesResponse } = useQuery("eventTypes", async () => {
    const api = await getSvix();
    return api.eventType.list({ limit: 250, withContent: true });
  });

  const selectedEventType = eventTypesResponse?.data.find(
    (et) => et.name === selectedEventTypeName
  );

  // update the test payload when selected eventType changes
  useEffect(() => {
    setTestOutput(undefined);
    setOutputUrl(undefined);
    setOutputMethod(undefined);
    if (selectedEventType && selectedEventType.schemas) {
      const schema = selectedEventType.schemas["1"];
      const test = generateSampleCodeForSchema(schema, schema.definition);
      const payload = JSON.stringify(test, null, 2);
      setTestPayload(payload);
    } else {
      setTestPayload("{}");
    }
  }, [selectedEventType, setTestOutput, setTestPayload]);

  // coordinate a test run
  const runTest = async () => {
    setError(undefined);
    setLoading(true);
    setTestOutput(undefined);
    setOutputUrl(undefined);
    setOutputMethod(undefined);

    const payload = validateAndParseJSON(testPayload);
    if (payload === false) {
      setError("Please edit the payload to ensure it contains valid JSON.");
      setLoading(false);
      return;
    }

    try {
      const sv = await getSvix();
      let response;

      if (appId && endpointId) {
        const api = new EndpointApi(sv._configuration);
        response = await api.v1EndpointTransformationSimulate({
          endpointId,
          appId,
          endpointTransformationSimulateIn: {
            eventType: selectedEventTypeName,
            code: props.code,
            payload,
          },
        });
      } else {
        const api = new TransformationTemplateApi(sv._configuration);
        response = await api.v1TransformationTemplateSimulate({
          transformationSimulateIn: {
            eventType: selectedEventTypeName,
            code,
            payload,
          },
        });
      }

      try {
        // Try to pretty-print it if it's valid json
        setTestOutput(JSON.stringify(JSON.parse(response.payload), null, 2));
      } catch (_) {
        setTestOutput(response.payload);
      }

      setOutputUrl(response.url);
      setOutputMethod(response.method ?? "POST");
    } catch (e) {
      setError(`An error occurred evaluating this transformation.\n${e.body.detail}`);
    }

    setLoading(false);
  };

  return (
    <Box>
      <Stack direction="column" spacing={4}>
        <HStack spacing={4}>
          <Text>Choose Payload:</Text>
          <Box w="16em" flexShrink={0}>
            <Select control={formCtx.control} name="eventType">
              <option value="custom">Custom</option>
              {eventTypesResponse?.data.map((eventType) => (
                <option value={eventType.name} key={eventType.name}>
                  {eventType.name}
                </option>
              ))}
            </Select>
          </Box>
          <Box flexGrow={1} />
          <Button
            colorScheme="brand"
            flexShrink={0}
            onClick={runTest}
            isLoading={loading}
          >
            Run Test
          </Button>
        </HStack>
        <Suspense fallback={<Skeleton height={24} />}>
          <CodeEditor lang={Lang.Json} value={testPayload} onChange={setTestPayload} />
        </Suspense>
        {error && (
          <Box>
            <Alert status="error">
              <AlertIcon />
              <pre style={{ fontFamily: "inherit" }}>{error}</pre>
            </Alert>
          </Box>
        )}
        {testOutput && (
          <Box>
            <FormLabel>Output:</FormLabel>
            <Box fontSize="smaller" fontFamily="mono" display="inline-block" mb="1em">
              <Text fontWeight="semibold" display="inline">
                {outputMethod}:
              </Text>
              <Text
                display="inline"
                bgColor="gray.200"
                padding="4px"
                rounded="sm"
                ml=".25em"
              >
                {outputUrl}
              </Text>
            </Box>
            <Suspense fallback={<Skeleton height={16} />}>
              <CodeEditor
                lang={Lang.Json}
                value={testOutput}
                onChange={setTestOutput}
                readOnly
              />
            </Suspense>
          </Box>
        )}
      </Stack>
    </Box>
  );
}

function validateAndParseJSON(str: string): any {
  try {
    return JSON.parse(str);
  } catch (_) {
    return false;
  }
}
