import { useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { EndpointOut } from "svix/dist/openapi";

import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { pushError } from "src/store/errors";
import RecoverRadio, { getDate, RecoverTime } from "./RecoverRadio";

interface IReplayForm {
  since: RecoverTime;
}

const defaultValues: IReplayForm = {
  since: "8-hours-ago",
};

interface IReplayModalProps {
  endpoint: EndpointOut;
  isOpen: boolean;
  onClose: () => void;
}

export default function ReplayModal(props: IReplayModalProps) {
  const { isOpen, endpoint, onClose } = props;
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const user = useAppSelector((state) => state.auth.user)!;

  async function replay(form: IReplayForm) {
    const api = getSvix();
    try {
      await api.endpoint.replayMissing(user.app.id, endpoint.id, {
        since: getDate(form.since),
      });
      await queryClient.invalidateQueries(["endpoints", endpoint.id, "messages"]);
      onClose();
    } catch (err) {
      dispatch(pushError(err));
    }
  }

  const formCtx = useForm<IReplayForm>({ defaultValues });
  const { reset } = formCtx;

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [isOpen, reset]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <Form onSubmit={replay} {...formCtx}>
        <ModalContent borderRadius="card">
          <ModalHeader>Replay Missing Messages</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text variant="caption" mb={4}>
              This operation will cause all messages that were never attempted for this
              endpoint to be resent.
            </Text>
            <Stack spacing={3}>
              <RecoverRadio control={formCtx.control} name="since" />
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" onClick={onClose} mr={2}>
              Cancel
            </Button>
            <SubmitButton>Replay</SubmitButton>
          </ModalFooter>
        </ModalContent>
      </Form>
    </Modal>
  );
}
