import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { v4 as uuidv4 } from "uuid";

import { JSONSchema7, JSONSchema7Definition, JSONSchema7TypeName } from "../types";

export function getSchemaType(
  type: JSONSchema7TypeName | undefined,
  schema: JSONSchema7,
  definitions: any
): string {
  if (type === "array") {
    const subSchema = Array.isArray(schema.items) ? schema.items[0] : schema.items;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return subSchema
      ? `${getSchemaType(type, subSchema as JSONSchema7, definitions)}[]`
      : "array";
  } else if (schema.$ref) {
    return getDefinitionName(schema.$ref) ?? "unknown";
  } else if (schema.allOf) {
    return "object";
  } else if (schema.anyOf) {
    return "anyOf";
  } else if (schema.oneOf) {
    return "oneOf";
  }

  return type ?? "object";
}

export function getDefinitionName(key: string): string | undefined {
  return key.split("/").pop();
}

export function getComponent(obj: any, key: string | undefined): JSONSchema7 | undefined {
  if (!key || !obj) {
    return undefined;
  }

  const parts = key.split("/");
  for (const part of parts) {
    if (part === "#") {
      continue;
    }
    obj = obj?.[part];
  }
  return obj;
}

export function getDefinition(
  definitions: JSONSchema7["definitions"],
  key: string | undefined
) {
  if (!key || !definitions) {
    return undefined;
  }
  const definition = getDefinitionName(key);
  return definition ? (definitions[definition] as JSONSchema7) : undefined;
}

export function getColorForType(type: JSONSchema7TypeName | undefined): string {
  if (!type) {
    return "blue";
  }

  return (
    {
      string: "gray",
      number: "red",
      integer: "red",
      array: "orange",
      object: "cyan",
      boolean: "green",
      null: "yellow",
    }[type] || "gray"
  );
}

function getExampleForType(
  schema: JSONSchema7,
  definitions: JSONSchema7["definitions"]
): any {
  const schemaDef = getDefinition(definitions, schema.$ref) ?? schema;

  // OpenAPI defines a non-array 'example' field.
  //
  // It's deprecated in OpenAPI 3.1 but that's very new so this field is still
  // found in a lot of places.
  const example = (schemaDef as any).example;
  if (example !== undefined) {
    return example;
  }

  if (Array.isArray(schemaDef.examples) && schemaDef.examples.length > 0) {
    return schemaDef.examples[0];
  }

  if (schemaDef.const !== undefined) {
    return schemaDef.const;
  }

  const type = schemaDef.type ?? "object";

  if (type === "string") {
    if (schemaDef.format) {
      if (schemaDef.format === "uuid") {
        return uuidv4();
      } else if (schemaDef.format === "date-time") {
        return new Date().toISOString();
      } else if (schemaDef.format === "date") {
        return new Date().toISOString().split("T")[0];
      }
      return schemaDef.format;
    }
    if (schemaDef.enum && schemaDef.enum.length > 0) {
      return schemaDef.enum[0];
    }
    return "some string";
  }
  if (type === "number") {
    return 3.14;
  }
  if (type === "integer") {
    return 42;
  }
  if (type === "boolean") {
    return true;
  }
  if (type === "array") {
    const subItem = Array.isArray(schemaDef.items) ? schemaDef.items[0] : schemaDef.items;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return subItem ? [getExampleForType(subItem as JSONSchema7, definitions)] : [];
  }
  if (schemaDef.oneOf) {
    return getExampleForType(schemaDef.oneOf[0] as JSONSchema7, definitions);
  }
  if (typeHasNestedProperties(schemaDef)) {
    const allProperties = schemaDef.properties || mergeProperties(schemaDef.allOf || []);
    return Object.entries(allProperties).reduce((acc, [key, itemSchema]) => {
      acc[key] = getExampleForType(itemSchema as JSONSchema7, definitions);
      return acc;
    }, {} as any);
  }
  return type;
}

export function typeHasNestedProperties(schema: JSONSchema7): boolean {
  const schemaType = schema.type ?? "object";
  if (Array.isArray(schemaType)) {
    return (
      isEqual(schemaType, ["object"]) ||
      isEqual([...schemaType].sort(), ["null", "object"])
    );
  }

  return schemaType === "object" || !!schema.allOf;
}

export function getSchemaExamples(
  schema: JSONSchema7,
  definitions: JSONSchema7["definitions"]
): any[] {
  const schemaDef = getDefinition(definitions, schema.$ref) ?? schema;

  // OpenAPI defines a non-array 'example' field.
  //
  // It's deprecated in OpenAPI 3.1 but that's very new so this field is still
  // found in a lot of places.
  const example = (schemaDef as any).example;
  if (example !== undefined) {
    return [example];
  }

  if (Array.isArray(schemaDef.examples) && schemaDef.examples.length > 0) {
    return schemaDef.examples;
  }

  return [];
}

export function generateSampleCodeForSchema(
  schema: JSONSchema7,
  definitions: JSONSchema7["definitions"]
): any {
  return getExampleForType(schema, definitions);
}

export function isSchemaConfigured(schema?: JSONSchema7): boolean {
  if (schema?.title) {
    return true;
  }
  return (
    !isEmpty(schema?.properties) ||
    !isEmpty(schema?.items) ||
    !isEmpty(schema?.allOf) ||
    !isEmpty(schema?.oneOf) ||
    !isEmpty(schema?.anyOf)
  );
}

export function schemaHasExamples(schema?: JSONSchema7): boolean {
  return schema?.examples?.[0] !== undefined;
}

export function mergeProperties(schemas: JSONSchema7Definition[]): {
  [key: string]: JSONSchema7Definition;
} {
  let merged: { [key: string]: JSONSchema7Definition } = {};

  (schemas as JSONSchema7[]).forEach((s) => {
    merged = { ...merged, ...s.properties };
  });
  return merged;
}
