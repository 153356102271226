import { useState, useCallback } from "react";
import {
  Image,
  ButtonProps,
  Collapse,
  HStack,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { EndpointIn } from "svix";

import Button from "@svix/common/widgets/Button";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";

import { useWindmillIntegration } from "src/hooks/integrations/windmill";
import windmillLogo from "src/img/integrations/windmill-icon.svg";

export type WindmillIntegrationProps = {
  formCtx: UseFormReturn<EndpointIn, any>;
};

export default function WindmillIntegration(props: WindmillIntegrationProps) {
  const { formCtx } = props;
  const [showEndpointURL, setShowEndpointURL] = useState(false);
  const [connectedToWindmill, setConnectedToWindmill] = useState(false);

  const onIncomingWebhookReceived = useCallback(
    (url: string) => {
      formCtx.setValue("url", url);
      setShowEndpointURL(true);
      setConnectedToWindmill(true);
    },
    [formCtx, setShowEndpointURL, setConnectedToWindmill]
  );

  const { onConnect, error: connectionError } = useWindmillIntegration(
    onIncomingWebhookReceived
  );

  return (
    <Stack>
      <Text fontWeight="semibold">Endpoint URL</Text>
      <HStack>
        <WindmillButton onClick={onConnect} isDisabled={connectedToWindmill}>
          {connectedToWindmill ? "Connected" : "Connect to Windmill"}
        </WindmillButton>
        <Text>
          {"or "}
          <StyledLink
            onClick={() => {
              setShowEndpointURL(true);
              setConnectedToWindmill(false);
            }}
          >
            <Tooltip
              label="Use an Windmill Webhook from Windmill Cloud, if you have created one"
              fontSize="md"
            >
              Add your own Windmill Webhook URL
            </Tooltip>
          </StyledLink>
        </Text>
      </HStack>
      <Collapse in={showEndpointURL}>
        <TextField
          control={formCtx.control}
          name="url"
          helperText="The URL where the webhook events will be sent to."
          type="url"
          isRequired
          isDisabled={connectedToWindmill}
          placeholder="e.g. https://www.example.com/webhook"
        />
      </Collapse>
      {connectionError && <Text color="red.500">{connectionError}</Text>}
    </Stack>
  );
}

const WindmillButton = (props: ButtonProps) => {
  const { children, ...rest } = props;
  return (
    <Button
      leftIcon={<Image height="1.4em" src={windmillLogo} />}
      {...rest}
      variant="outline"
    >
      {children}
    </Button>
  );
};
