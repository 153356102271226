import { accordionAnatomy, tableAnatomy } from "@chakra-ui/anatomy";
import {
  defineStyle,
  defineStyleConfig,
  extendTheme,
  theme as baseTheme,
  createMultiStyleConfigHelpers,
} from "@chakra-ui/react";
import { mode, StyleFunctionProps } from "@chakra-ui/theme-tools";
import { StepsTheme as Steps } from "chakra-ui-steps";

const accordionHelpers = createMultiStyleConfigHelpers(accordionAnatomy.keys);
const Accordion = accordionHelpers.defineMultiStyleConfig({
  baseStyle: accordionHelpers.definePartsStyle({
    root: {},
    container: {},
    button: {
      paddingX: 0,
      paddingY: "12px",
    },
    panel: {
      paddingX: 0,
    },
    icon: {},
  }),
});

const Alert = defineStyleConfig({
  variants: {
    plain: defineStyle((props) => ({
      ...baseTheme.components.Alert.variants?.subtle(props),
      container: {
        bgColor: "background.secondary",
        borderColor: "background.modifier.border",
        borderWidth: "1px",
      },
    })),
  },
});

const Card = defineStyleConfig({
  baseStyle: defineStyle((props) => ({
    bgColor: "background.secondary",
    border: "1px solid",
    borderColor: "background.modifier.border",
    borderRadius: props.theme.radii.card,
  })),
});

const CardBody = defineStyleConfig({
  baseStyle: defineStyle((_props) => ({
    padding: 5,
  })),
});

const Checkbox = defineStyleConfig({
  defaultProps: {
    colorScheme: "brand",
  },
});

const Heading = defineStyleConfig({
  baseStyle: {
    color: "text.heading",
  },
});

const Sidebar = defineStyleConfig({
  baseStyle: defineStyle((props) => ({
    borderRight: "1px solid",
    borderColor: "background.modifier.border",
    color: mode("gray.600", "gray.300")(props),
    bgColor: "background.secondary",
  })),
});

const simpleTableVariant = (props: StyleFunctionProps) => {
  const baseSimpleVariant = baseTheme.components.Table.variants?.simple(props);
  return {
    ...baseSimpleVariant,
    borderRadius: props.theme.radii.table,
    td: {
      ...baseSimpleVariant?.td,
      paddingTop: 0,
      paddingBottom: 0,
    },
    thead: {
      th: {
        borderBottom: "1px solid",
        borderColor: "background.modifier.border",
        height: "40px",
      },
      tr: {
        backgroundColor: "background.hover",
      },
    },
  };
};

const tableHelpers = createMultiStyleConfigHelpers(tableAnatomy.keys);
const Table = tableHelpers.defineMultiStyleConfig({
  variants: {
    simple: simpleTableVariant,
    hover: (props: StyleFunctionProps) => {
      const simpleVariant = simpleTableVariant(props);
      return {
        ...simpleVariant,
        td: {
          ...simpleVariant.td,
          cursor: "pointer",
          paddingTop: 0,
          paddingBottom: 0,
        },
        tr: {
          "tbody &": {
            transition: "background 0.15s ease-in-out",
            _hover: {
              backgroundColor: "background.hover",
            },
          },
        },
      };
    },
  },
});

const inputFocusStyle = (props: StyleFunctionProps) => ({
  field: {
    borderRadius: props.theme.radii.input,
  },
});

const Input = defineStyleConfig({
  variants: {
    outline: inputFocusStyle,
    filled: inputFocusStyle,
    flushed: inputFocusStyle,
  },
});

const Tabs = defineStyleConfig({
  baseStyle: {
    tabpanel: {
      px: 0,
    },
  },
});

const Text = defineStyleConfig({
  variants: {
    caption: {
      color: "text.muted",
    },
  },
});

const Tooltip = defineStyleConfig({
  baseStyle: {
    borderRadius: "md",
    padding: 2,
  },
});

const buttonStyles = {
  toolbar: defineStyle((props) => ({
    ...baseTheme.components.Button.variants?.outline(props),
    borderRadius: props.theme.radii.button,
    bgColor: mode("white", "gray.800")(props),
  })),
  rounded: defineStyle((props) => ({
    ...baseTheme.components.Button.variants?.ghost(props),
    radii: "full",
  })),
  appbar: defineStyle((props) => ({
    ...baseTheme.components.Button.variants?.ghost(props),
    radii: "full",
    color: "white",
    _active: { bg: "brand.600" },
    _hover: { bg: "brand.600" },
  })),
  filterGroupActive: defineStyle((props) => ({
    ...baseTheme.components.Button.variants?.solid(props),
    bgColor: mode("gray.200", "gray.700")(props),
    boxShadow: "inner",
    border: "1px solid",
    borderColor: mode("gray.300", "gray.700")(props),
    borderRadius: props.theme.radii.button,
    ml: "-1px",
    _active: { bg: mode("gray.300", "gray.600")(props) },
    _hover: { bg: mode("gray.300", "gray.600")(props) },
  })),
  filterGroupInactive: defineStyle((props) => ({
    ...baseTheme.components.Button.variants?.outline(props),
    bgColor: mode("white", "transparent")(props),
    borderColor: mode("gray.300", "gray.700")(props),
    borderRadius: props.theme.radii.button,
    ml: "-1px",
    _active: { borderColor: mode("gray.300", "gray.600")(props) },
    _hover: { borderColor: mode("gray.300", "gray.600")(props) },
  })),
  outline: defineStyle((props) => {
    return {
      borderRadius: props.theme.radii.button,
    };
  }),
  outlineSecondary: defineStyle((props) => {
    let styles = {
      ...baseTheme.components.Button.variants?.outline(props),
      borderRadius: props.theme.radii.button,
    };

    if (props.colorScheme === "brand") {
      styles = {
        ...styles,
        color: "button.primary",
        borderColor: "button.primary",
        _hover: {
          bg: "button.outlineHover",
        },
        _active: {
          bg: "button.outlineHover",
        },
      };
    }

    return styles;
  }),
  solid: defineStyle((props) => {
    let style: any = {
      borderRadius: props.theme.radii.button,
    };

    if (props.colorScheme === "brand") {
      style = {
        ...style,
        bgColor: "button.primary",
        _hover: {
          bgColor: "button.hover",
        },
      };
    }

    return style;
  }),
};

const Button = defineStyleConfig({
  variants: buttonStyles,
});

const theme = extendTheme({
  colors: {
    brand: baseTheme.colors.blue,
    text: {
      danger: "var(--color-text-danger)",
      heading: "var(--color-text-heading)",
      muted: "var(--color-text-muted)",
      primary: "var(--color-text-primary)",
    },
    background: {
      primary: "var(--color-background-primary)",
      secondary: "var(--color-background-secondary)",
      hover: "var(--color-background-hover)",
      modifier: {
        border: "var(--color-background-modifier-border)",
      },
    },
    interactive: {
      accent: "var(--color-interactive-accent)",
      accentHover: "var(--color-interactive-accentHover)",
    },
  },
  config: {
    useSystemColorMode: false,
  },
  components: {
    Accordion,
    Alert,
    Steps,
    Button,
    Card,
    CardBody,
    Checkbox,
    Heading,
    Input,
    Sidebar,
    Tabs,
    Table,
    Text,
    Tooltip,
  },
  radii: {
    card: "0.5rem",
    button: "0.375rem",
    input: "0.375rem",
    table: "0.375rem",
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        "--chakra-colors-interactive-accent": mode(
          baseTheme.colors.blue["500"],
          baseTheme.colors.blue["400"]
        )(props),
        "--chakra-colors-interactive-accentHover": mode(
          baseTheme.colors.blue["600"],
          baseTheme.colors.blue["300"]
        )(props),
        "--chakra-colors-background-primary": mode(
          "#f8f9fd",
          baseTheme.colors.gray["800"]
        )(props),
        "--chakra-colors-background-secondary": mode(
          "white",
          baseTheme.colors.gray["900"]
        )(props),
        "--chakra-colors-background-hover": mode(
          baseTheme.colors.gray["100"],
          baseTheme.colors.gray["800"]
        )(props),
        "--chakra-colors-text-primary": mode(
          baseTheme.colors.gray["800"],
          "white"
        )(props),
        "--chakra-colors-text-heading": mode("#495072", "#f8f9fd")(props),
        "--chakra-colors-text-danger": mode(
          baseTheme.colors.red["500"],
          baseTheme.colors.red["300"]
        )(props),
        "--chakra-colors-text-muted": mode(
          baseTheme.colors.gray["500"],
          baseTheme.colors.gray["300"]
        )(props),
        "--chakra-colors-background-modifier-border": mode(
          "#dfe0ea",
          baseTheme.colors.gray["700"]
        )(props),
      },
    }),
  },
});

export default theme;
