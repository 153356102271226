import { lazy, useState, Suspense, useEffect } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Heading,
  Box,
  Divider,
  Flex,
  Stack,
  HStack,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowForwardIos } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { EndpointIn, TemplateOut, TransformationTemplateKind } from "svix";
import { TransformationTemplateApi } from "svix/dist/openapi";
import * as yup from "yup";

import { setErrors } from "@svix/common/formUtils";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import { Lang } from "@svix/common/widgets/form/CodeEditor/Lang";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAllEventTypes } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import DiscordIntegration from "./TemplateIntegrations/DiscordIntegration";
import HubspotIntegration from "./TemplateIntegrations/HubspotIntegration";
import InngestIntegration from "./TemplateIntegrations/InngestIntegration";
import SlackIntegration from "./TemplateIntegrations/SlackIntegration";
import TeamsIntegration from "./TemplateIntegrations/TeamsIntegration";
import WindmillIntegration from "./TemplateIntegrations/WindmillIntegration";
import ZapierIntegration from "./TemplateIntegrations/ZapierIntegration";

const CodeEditor = lazy(() => import("@svix/common/widgets/form/CodeEditor"));
const TestTransformation = lazy(() => import("@svix/common/widgets/TestTransformation"));

const schema = yup.object().shape({
  url: yup.string().url(),
  description: yup.string(),
  filterTypes: yup.mixed(),
  transformation: yup.string(),
});

export const templateIdMetadataField = "_svix_transformation_template_id";

export default function TransformationTemplateForm(props: { template: TemplateOut }) {
  const { template } = props;
  const history = useHistory();
  const user = useAppSelector((state) => state.auth.user)!;

  const [code, setCode] = useState(template.transformation);
  const [hubspotRefreshToken, setHubspotRefreshToken] = useState<string | undefined>();

  const formCtx = useForm<EndpointIn>({
    defaultValues: {
      filterTypes: template.filterTypes,
      description: template.description,
      url: "",
    },
    resolver: yupResolver(schema),
  });
  const { data: eventTypesData } = useAllEventTypes();

  async function onAddEndpoint(form: EndpointIn) {
    const dh = getSvix();

    if (form.filterTypes && form.filterTypes.length === 0) {
      form["filterTypes"] = template.filterTypes;
    }

    try {
      const endpoint = await dh.endpoint.create(user.app.id, {
        ...form,
        metadata: {
          [templateIdMetadataField]: template.id,
        },
      });

      // FIXME: Move hubspot-specific logic into its own component
      if (hubspotRefreshToken) {
        const ttApi = new TransformationTemplateApi(dh._configuration);
        await ttApi.v1EndpointUpdateHubspotOauthConfig({
          appId: user.app.id,
          endpointId: endpoint.id,
          hubspotOauthConfigIn: {
            refreshToken: hubspotRefreshToken,
          },
        });
      }

      await dh.endpoint.transformationPartialUpdate(user.app.id, endpoint.id, {
        enabled: true,
        code: code,
      });

      history.push(routeResolver.getRoute("endpoints._id", { endpId: endpoint.id }));
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  const endpointField = (templateKind: TransformationTemplateKind) => {
    switch (templateKind) {
      case "Discord":
        return <DiscordIntegration formCtx={formCtx} />;
      case "Hubspot":
        return (
          <HubspotIntegration
            formCtx={formCtx}
            refreshToken={hubspotRefreshToken}
            setRefreshToken={setHubspotRefreshToken}
          />
        );
      case "Inngest":
        return <InngestIntegration formCtx={formCtx} />;
      case "Slack":
        return <SlackIntegration formCtx={formCtx} />;
      case "Teams":
        return <TeamsIntegration formCtx={formCtx} />;
      case "Zapier":
        return <ZapierIntegration formCtx={formCtx} />;
      case "Windmill":
        return <WindmillIntegration formCtx={formCtx} />;
      default:
        return (
          <>
            {template.instructions && (
              <>
                <Text fontWeight="semibold">Instructions</Text>
                <Text whiteSpace="pre-wrap">{template.instructions}</Text>
              </>
            )}
            {template.instructionsLink && (
              <StyledLink
                fontSize="sm"
                color="interactive.accent"
                to={template.instructionsLink}
              >
                Learn more
              </StyledLink>
            )}
            <TextField
              autoFocus
              control={formCtx.control}
              name="url"
              label="Endpoint URL"
              helperText="The URL where the webhook events will be sent to."
              type="url"
              isRequired
              placeholder="e.g. https://www.example.com/webhook"
            />
          </>
        );
    }
  };

  useEffect(() => {
    setCode(template.transformation);
  }, [template.transformation]);

  return (
    <Form onSubmit={onAddEndpoint} {...formCtx}>
      <Box maxW="50em">
        <GeneralFormErrors />
        <Stack spacing={8}>
          {endpointField(template.kind)}
          <Box w="100%">
            <EventsList
              availableEvents={
                eventTypesData?.data.filter((et) =>
                  template.filterTypes?.includes(et.name)
                ) || []
              }
              control={formCtx.control}
              name="filterTypes"
              label={
                <Stack>
                  <Flex alignItems="center" justifyContent="space-between">
                    <span>Subscribe to events</span>
                    <StyledLink
                      fontSize="sm"
                      display="flex"
                      alignItems="center"
                      color="interactive.accent"
                      to={routeResolver.getRoute("event-types")}
                    >
                      Event Catalog
                      <ArrowForwardIos style={{ fontSize: 15, marginLeft: 4 }} />
                    </StyledLink>
                  </Flex>
                  <Text fontSize="sm" color="text.secondary" fontWeight="normal">
                    These are the events supported by this connector. Select the ones you
                    want to receive.
                  </Text>
                </Stack>
              }
              emptyState="Receiving all events."
            />
          </Box>
          <input name="version" type="hidden" value={1} />
          <Stack spacing={1}>
            <Text fontWeight="semibold">Transformation code</Text>
            <Text fontSize="sm" color="text.secondary">
              This is the code that will be executed when an event is received. You can
              modify it to customize your integration.
            </Text>
            <Suspense fallback={<Skeleton h="12em" />}>
              <CodeEditor lang={Lang.Javascript} value={code} onChange={setCode} dark />
            </Suspense>
            <Suspense fallback={<Skeleton h={6} />}>
              <Accordion allowMultiple>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Heading size="sm" as="div" flex="1" textAlign="left">
                          Test transformation
                        </Heading>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <TestTransformation
                      code={code}
                      getSvix={getSvix}
                      appId={user.app.id}
                    />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Suspense>
          </Stack>
        </Stack>
        <Divider mt={4} />
        <HStack mt={4} spacing={4}>
          <Button colorScheme="gray" as={Link} to={routeResolver.getRoute("endpoints")}>
            Cancel
          </Button>
          <SubmitButton isLoading={formCtx.formState.isSubmitting}>Create</SubmitButton>
        </HStack>
      </Box>
    </Form>
  );
}
