import { Box, ButtonGroup, Flex, Heading, HStack, Tooltip } from "@chakra-ui/react";
import { Info } from "@material-ui/icons";
import { EndpointIn, EndpointOut } from "svix";

import useUpdateMutation from "@svix/common/hooks/mutate";
import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";
import { isDynamicEndpointUrl } from "../Endpoints";

interface IEndpointUrlProps {
  endpoint: EndpointOut;
}

export default function EndpointUrl(props: IEndpointUrlProps) {
  const { endpoint } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const queryKey = ["endpoints", endpoint.id];

  const { edit, cancelEdit, formCtx, mutate, isEditing, isSubmitting } =
    useUpdateMutation<EndpointOut>(queryKey, endpoint, "url", async (form) => {
      const api = getSvix();
      return api.endpoint.update(user.app.id, endpoint.id, form as EndpointIn);
    });

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);

  if (isDynamicEndpointUrl(endpoint.url)) {
    return (
      <HStack spacing={1} mb={6}>
        <Heading as="h1" size="sm">
          Dynamic endpoint
        </Heading>
        <Tooltip label="The URL of this endpoint is set dynamically on every message.">
          <Info style={{ fontSize: "1rem", opacity: 0.4 }} />
        </Tooltip>
      </HStack>
    );
  }

  return (
    <Form onSubmit={mutate} {...formCtx}>
      <Box display="flex" mb={6}>
        {isEditing ? (
          <Flex alignItems="center" w="100%">
            <TextField
              borderRadius="input"
              size="sm"
              autoFocus
              name="url"
              control={formCtx.control}
            />

            <ButtonGroup size="sm" ml={2}>
              <Button colorScheme="gray" onClick={cancelEdit}>
                Cancel
              </Button>
              <SubmitButton key="save" isLoading={isSubmitting}>
                Save
              </SubmitButton>
            </ButtonGroup>
          </Flex>
        ) : (
          <Flex alignItems="center" w="100%" justifyContent="space-between">
            <Heading as="h1" size="sm" isTruncated>
              {endpoint.url}
            </Heading>
            <ReadOnlyTooltip readOnly={isReadOnly}>
              <Button
                isDisabled={isReadOnly}
                size="sm"
                colorScheme="gray"
                variant="outline"
                onClick={edit}
                ml="auto"
              >
                Edit
              </Button>
            </ReadOnlyTooltip>
          </Flex>
        )}
      </Box>
    </Form>
  );
}
