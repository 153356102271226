import * as React from "react";
import { useState } from "react";
import {
  Box,
  Code,
  Grid,
  Heading,
  Flex,
  IconButton,
  Collapse,
  Tag,
  Text,
  useBoolean,
  Tooltip,
  Select,
} from "@chakra-ui/react";
import { ArrowRight } from "@material-ui/icons";
import { cloneDeep } from "lodash";
import size from "lodash/size";

import {
  generateSampleCodeForSchema,
  getColorForType,
  getComponent,
  getDefinition,
  getSchemaExamples,
  getSchemaType,
  mergeProperties,
  typeHasNestedProperties,
} from "./utils";
import Card from "../../Card";
import CodeBlock from "../../Code";
import Markdown from "../../Markdown";
import { JSONSchema7, JSONSchema7Definition } from "../types";

interface ISchemaPreviewerProps {
  schema: JSONSchema7;
  version: number;
  title?: string;
  autogenerateExample?: boolean;
  noExampleElement?: React.ReactNode;
  onSelectExample?: (example: number) => void;
}

interface ISchemaItemProps {
  definitions: JSONSchema7["definitions"];
  schema: JSONSchema7Definition;
  mainSchema: JSONSchema7Definition;
  isRequired: boolean;
  name: string;
}

function SchemaPropertyFieldValue(
  schema: JSONSchema7,
  fieldName: keyof JSONSchema7
): React.ReactNode {
  const value = schema[fieldName];

  if (fieldName === "const") {
    return <Code marginRight="0.5em">{JSON.stringify(value)}</Code>;
  }

  if (Array.isArray(value)) {
    return (
      <>
        {value.map((x: any) => (
          <Code key={x} marginRight="0.5em">
            {String(x)}
          </Code>
        ))}
      </>
    );
  }
  return String(value);
}

function SchemaPropertiesTable(props: ISchemaItemProps) {
  const schema = props.schema as JSONSchema7;
  const fields: (keyof JSONSchema7)[] = [
    "title",
    "format",
    "pattern",
    "minLength",
    "maxLength",
    "minimum",
    "maximum",
    "enum",
    "const",
  ];

  const variants = schema.anyOf ?? schema.oneOf;
  if (variants) {
    return (
      <div className="divide-y">
        {variants.map((itemSchema, i) => {
          const variantSchema = cloneDeep(itemSchema) as JSONSchema7;
          // Merge the 'regular' properties with the 'anyOf', 'oneOf' ones
          // So, { a, oneOf: [b, c] } is shown as { oneOf: [[a, b], [a, c]] }
          variantSchema.properties = {
            ...variantSchema.properties,
            ...schema.properties,
          };
          return (
            <SchemaItem
              definitions={props.definitions}
              name={`Item ${i}`}
              isRequired={false}
              schema={variantSchema}
              mainSchema={props.mainSchema}
              key={i.toString()}
            />
          );
        })}
      </div>
    );
  } else if (schema.type === "array") {
    const itemSchema = Array.isArray(schema.items) ? schema.items[0] : schema.items;
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return itemSchema ? (
      <SchemaItem
        definitions={props.definitions}
        name="Item"
        schema={itemSchema}
        mainSchema={props.mainSchema}
        isRequired={false}
      />
    ) : null;
  } else if (typeHasNestedProperties(schema)) {
    let properties = schema.properties;
    if (schema.allOf) {
      properties = mergeProperties(schema.allOf);
    }

    return (
      <>
        {Object.entries(properties || {}).map(([propName, itemSchema]) => (
          <SchemaItem
            definitions={props.definitions}
            name={propName}
            isRequired={schema.required?.indexOf(propName) !== -1}
            schema={itemSchema}
            mainSchema={props.mainSchema}
            key={propName}
          />
        ))}
      </>
    );
  }

  return (
    <Box
      borderRadius="lg"
      p={2}
      border="1px solid"
      borderColor="background.modifier.border"
      mb={2}
    >
      <Flex p={1}>
        <Flex
          w="25%"
          fontSize="smaller"
          textTransform="uppercase"
          fontWeight="bold"
          fontFamily="heading"
          letterSpacing="wider"
        >
          Field name
        </Flex>
        <Flex w="75%" overflowX="auto">
          <Code>{props.name}</Code>
        </Flex>
      </Flex>

      {fields
        .filter((field) => Object.prototype.hasOwnProperty.call(schema, field))
        .map((field) => (
          <Flex
            borderTop="1px solid"
            borderColor="background.modifier.border"
            p={1}
            key={field}
          >
            <Box
              w="25%"
              fontSize="smaller"
              textTransform="uppercase"
              fontWeight="bold"
              fontFamily="heading"
              letterSpacing="wider"
            >
              {field}
            </Box>
            <Box w="75%" overflowX="auto" overflowY="auto" maxH="20em">
              {SchemaPropertyFieldValue(schema, field)}
            </Box>
          </Flex>
        ))}
    </Box>
  );
}

function SchemaItem(props: ISchemaItemProps) {
  const { definitions, name, schema, isRequired, mainSchema } = props;
  const [isExpanded, setExpanded] = useBoolean();

  if (typeof schema === "boolean") {
    return null;
  }

  let itemSchema = schema;
  if (schema.$ref) {
    // FIXME: can probably just get rid of getDefinition, I think getComponent can do everything.
    const definition = getDefinition(definitions, schema.$ref);
    const component = getComponent(mainSchema, schema.$ref);
    itemSchema = definition ?? component ?? schema;
  }

  return (
    <Box
      data-cy={`schema-item--${name}`}
      _notFirst={{ borderTop: "1px solid", borderTopColor: "background.modifier.border" }}
    >
      <Flex alignItems="center" onClick={setExpanded.toggle} cursor="pointer" my={2}>
        <IconButton aria-label="toggle expand" variant="ghost" size="xs" color="gray.600">
          <ArrowRight
            fontSize="small"
            style={{
              transition: "transform 0.15s ease-in",
              transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
            }}
          />
        </IconButton>
        <Heading size="xs">
          {itemSchema.deprecated ? (
            <Text>
              <Text as="s">{name}</Text>
              <Tooltip
                hasArrow
                label="This field may be removed in the future and should not be used."
              >
                <Tag
                  fontFamily="mono"
                  size="sm"
                  colorScheme="red"
                  ml={2}
                  fontWeight="semibold"
                >
                  Deprecated
                </Tag>
              </Tooltip>
            </Text>
          ) : (
            name
          )}
        </Heading>
        {(Array.isArray(schema.type) ? schema.type : [schema.type]).map((type, idx) => (
          <Tag
            key={idx}
            fontFamily="mono"
            size="sm"
            ml={2}
            colorScheme={getColorForType(type)}
            fontWeight="semibold"
          >
            {getSchemaType(type, schema as JSONSchema7, props.definitions)}
          </Tag>
        ))}
        {itemSchema.const !== undefined && (
          <Tag
            size="sm"
            ml={2}
            colorScheme="blackAlpha"
            fontFamily="mono"
            fontWeight="semibold"
          >
            const
          </Tag>
        )}
        {itemSchema.format && (
          <Tag size="sm" ml={2} colorScheme="blue">
            ({itemSchema.format})
          </Tag>
        )}
        {!isRequired && (
          <Text ml={2} as="span" variant="caption" fontStyle="italic">
            Optional
          </Text>
        )}
      </Flex>
      {itemSchema.description && (
        <Box m={2} fontSize="sm">
          <Text variant="caption">
            <Markdown>{itemSchema.description}</Markdown>
          </Text>
        </Box>
      )}
      <Box>
        {itemSchema.type === "object" && (
          <Text color="teal.500">{size(itemSchema.properties)} Items</Text>
        )}
      </Box>
      <Collapse in={isExpanded} animateOpacity>
        <Box py={2} px={4}>
          <SchemaPropertiesTable {...props} schema={itemSchema} />
        </Box>
      </Collapse>
    </Box>
  );
}

export default function SchemaPreviewer(props: ISchemaPreviewerProps) {
  const { schema, autogenerateExample } = props;
  const codeExamples = getSchemaExamples(schema, schema.definitions);
  if (autogenerateExample && codeExamples.length === 0) {
    codeExamples.push(generateSampleCodeForSchema(schema, schema.definitions));
  }

  let rootItem: React.ReactNode;
  const variants = schema.anyOf ?? schema.oneOf;

  if (variants) {
    rootItem = (
      <div className="divide-y">
        <SchemaItem
          schema={schema}
          mainSchema={schema}
          definitions={schema.definitions}
          name={schema.title || "root"}
          isRequired={false}
        />
      </div>
    );
  } else {
    let properties = schema.properties;
    if (schema.allOf) {
      properties = mergeProperties(schema.allOf);
    }

    rootItem = (
      <>
        {properties &&
          Object.entries(properties).map(([name, itemSchema]) => (
            <SchemaItem
              schema={itemSchema}
              mainSchema={schema}
              definitions={schema.definitions}
              name={name}
              isRequired={schema.required?.indexOf(name) !== -1}
              key={name}
            />
          ))}
      </>
    );
  }

  const onSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const v = parseInt(e.target.value);
    setSelectedExample(v);
    props.onSelectExample && props.onSelectExample(v);
  };

  const [selectedExample, setSelectedExample] = useState<number>(0);
  let title: string | React.ReactNode = `Example ${schema.title ?? ""}`;
  if (codeExamples.length > 1) {
    title = (
      <Select
        fontSize="sm"
        variant="unstyled"
        w="fit-content"
        onChange={onSelectChange}
        value={selectedExample}
      >
        {codeExamples.map((_, idx) => (
          <option value={idx} key={idx}>{`Example ${idx + 1}`}</option>
        ))}
      </Select>
    );
  }

  return (
    <Grid gridTemplateColumns={{ base: "1fr", lg: "6fr 5fr" }} gap={4}>
      <Card display="flex" flexDir="column" padding={4}>
        {rootItem}
      </Card>
      {codeExamples.length > 0 ? (
        <CodeBlock
          copyToClipboard
          code={JSON.stringify(codeExamples[selectedExample], null, 2)}
          title={title}
          language="json"
          maxH="30em"
        />
      ) : (
        props.noExampleElement
      )}
    </Grid>
  );
}
