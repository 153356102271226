import * as React from "react";
import {
  Alert,
  AlertIcon,
  AlertDescription,
  Box,
  Code,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  ThemeTypings,
} from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";

import Button from "./Button";
import Form from "./Form";
import TextField from "./form/TextField";
import SubmitButton from "./SubmitButton";
import { getApiError } from "../utils";

interface IConfirmationDialogProps {
  isOpen: boolean;
  children: React.ReactNode;
  title: string;
  colorScheme?: ThemeTypings["colorSchemes"];
  labelOk?: string;
  typeToConfirmStr?: string;
  onCancel?: () => void;
  onOk: () => void | Promise<void>;
}

export default function ConfirmationDialog(props: IConfirmationDialogProps) {
  const {
    colorScheme,
    children,
    title,
    onCancel,
    onOk,
    labelOk,
    typeToConfirmStr,
    ...rest
  } = props;
  const [error, setError] = React.useState("");
  const formCtx = useForm({ defaultValues: { confirmationStr: "" } });
  const { reset } = formCtx;

  const onSubmit = async () => {
    try {
      await onOk();
    } catch (e) {
      setError(getApiError(e));
    }
  };

  React.useEffect(() => {
    if (props.isOpen) {
      reset();
      setError("");
    }
  }, [props.isOpen, reset]);

  const confirmationStr = useWatch({
    control: formCtx.control,
    name: "confirmationStr",
    defaultValue: "",
  });

  const isSubmitDisabled = typeToConfirmStr
    ? confirmationStr.trim() !== typeToConfirmStr
    : undefined;

  return (
    <Modal onClose={onCancel || onOk} {...rest}>
      <ModalOverlay />
      <Form onSubmit={onSubmit} {...formCtx}>
        <ModalContent borderRadius="lg">
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {error && (
              <Alert status="error" mb={4} borderRadius="md">
                <AlertIcon />
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            {children}
            {typeToConfirmStr && (
              <Box mt={4}>
                <TextField
                  autoFocus
                  label={
                    <>
                      Type <Code>{typeToConfirmStr}</Code> to confirm.
                    </>
                  }
                  control={formCtx.control}
                  name="confirmationStr"
                />
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            {onCancel && (
              <Button variant="outline" onClick={onCancel} mr={2}>
                Nevermind
              </Button>
            )}
            <SubmitButton
              isDisabled={isSubmitDisabled}
              colorScheme={colorScheme}
              showLoading
            >
              {labelOk || "Confirm"}
            </SubmitButton>
          </ModalFooter>
        </ModalContent>
      </Form>
    </Modal>
  );
}
