import { combineReducers, configureStore, PreloadedState } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistStore } from "redux-persist";
import persistReducer from "redux-persist/es/persistReducer";
import localStorage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";

import { inIframe } from "@svix/common/utils";

import applications from "./applications";
import errors from "./errors";
import featureFlags from "./featureFlags";
import tour from "./tour";

// Use localStorage when not in iframe to support opening multiple App Portal tabs.
const storage = inIframe() ? sessionStorage : localStorage;

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["featureFlags", "tour"],
};

const applicationsPersistConfig = {
  key: "applications",
  storage,
  blacklist: ["appId"],
};

const rootReducer = combineReducers({
  applications: persistReducer(applicationsPersistConfig, applications),
  errors,
  featureFlags,
  tour,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Export this to set up test store in tests
export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => {
      let middleware = getDefaultMiddleware({
        serializableCheck: false,
      });
      if (import.meta.env.NODE_ENV === "development") {
        middleware = middleware.concat(logger);
      }
      return middleware;
    },
    preloadedState,
  });
};

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;

export const persistor = persistStore(store);
