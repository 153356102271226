import { useEffect } from "react";
import {
  Box,
  FormLabel,
  Link as StyledLink,
  Stack,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { useForm, useWatch } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { EndpointOut } from "svix";
import { EndpointApi, EventExampleIn } from "svix/dist/openapi";

import Card from "@svix/common/widgets/Card";
import Form from "@svix/common/widgets/Form";
import Select from "@svix/common/widgets/form/Select";
import SchemaPreviewer from "@svix/common/widgets/JsonSchema/SchemaPreviewer";
import { schemaHasExamples } from "@svix/common/widgets/JsonSchema/SchemaPreviewer/utils";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppQuery, useAvailableEventTypes } from "src/hooks/api";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { pushError } from "src/store/errors";
import { wait } from "src/utils";

interface ISendExampleMessageProps {
  endpoint: EndpointOut;
}

export default function SendExampleMessage(props: ISendExampleMessageProps) {
  const { endpoint } = props;
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user)!;
  const queryClient = useQueryClient();
  const eventTypes = useAvailableEventTypes(endpoint);
  const queryKey = ["endpoints", endpoint.id];

  const formCtx = useForm<EventExampleIn>({
    defaultValues: {
      eventType: "",
      exampleIndex: 0,
    },
  });
  const { watch, setValue } = formCtx;

  async function sendExampleMsg(form: EventExampleIn) {
    const sv = getSvix();
    const config = sv._configuration;
    const endpApi = new EndpointApi(config);
    if (!form.eventType) {
      return;
    }

    try {
      await endpApi.v1EndpointSendExample({
        appId: user.app.id,
        endpointId: endpoint.id,
        eventExampleIn: form,
      });
      // Wait 2s for the message to be processed before refreshing
      await wait(2000);
      await queryClient.invalidateQueries([...queryKey, "messages"]);
    } catch (err) {
      dispatch(pushError(err));
    }
  }

  const exampleEvent = watch("eventType");
  useEffect(() => {
    setValue("exampleIndex", 0);
  }, [exampleEvent, setValue]);

  const selectedEventTypeName = useWatch({
    control: formCtx.control,
    name: "eventType",
    defaultValue: "",
  });

  const { data: selectedEventType } = useAppQuery(
    ["eventTypes", selectedEventTypeName],
    () => {
      const api = getSvix();
      return api.eventType.get(selectedEventTypeName);
    },
    {
      enabled: !!selectedEventTypeName,
    }
  );

  const hasEvents =
    (eventTypes.subscribedEvents?.length ?? 0) > 0 ||
    (eventTypes.unsubscribedEvents?.length ?? 0) > 0;

  const hasExample = schemaHasExamples(selectedEventType?.schemas?.["1"]);

  return (
    <Card title="Example Message">
      <Text mt={2} mb={5}>
        To test your integration, ping this endpoint with an example event. For more
        information about each event type, take a look at the{" "}
        <StyledLink
          as={Link}
          color="interactive.accent"
          to={routeResolver.getRoute("event-types")}
        >
          Event Catalog
        </StyledLink>
        .
      </Text>
      <Text mt={2} mb={4}>
        <strong>Note:</strong>
        <Box as="span" ml={1}>
          Failed messages sent this way will not be retried.
        </Box>
      </Text>
      <Form shouldPromptOnDirty={false} onSubmit={sendExampleMsg} {...formCtx}>
        <Stack spacing={5}>
          <Select
            label="Send event"
            control={formCtx.control}
            name="eventType"
            maxW="24em"
          >
            <option disabled value="">
              Select an event type
            </option>
            {!hasEvents && <option value="svix.ping">svix.ping</option>}
            <optgroup label="Subscribed events">
              {eventTypes.subscribedEvents?.map((eventType) => (
                <option value={eventType} key={eventType}>
                  {eventType}
                </option>
              ))}
            </optgroup>
            <optgroup label="Other">
              {eventTypes.unsubscribedEvents?.map((eventType) => (
                <option value={eventType} key={eventType}>
                  {eventType}
                </option>
              ))}
            </optgroup>
          </Select>
          <Box>
            <FormLabel>Schema</FormLabel>
            {selectedEventType?.schemas?.["1"] ? (
              <SchemaPreviewer
                schema={selectedEventType.schemas["1"]}
                version={1}
                onSelectExample={(idx) => formCtx.setValue("exampleIndex", idx)}
              />
            ) : (
              <Card maxW="24em">
                There's no schema defined for this event type. The message payload might
                be empty.
              </Card>
            )}
          </Box>
        </Stack>
        <Box mt={5}>
          <Tooltip
            label="This event type does not have any configured examples"
            shouldWrapChildren
            isDisabled={hasExample}
          >
            <SubmitButton
              isLoading={formCtx.formState.isSubmitting}
              size="sm"
              isDisabled={!hasExample}
            >
              Send Example
            </SubmitButton>
          </Tooltip>
        </Box>
      </Form>
    </Card>
  );
}
