import * as React from "react";
import { useEffect } from "react";
import { useColorMode, ChakraProvider, CSSReset, extendTheme } from "@chakra-ui/react";
import { mode, StyleFunctionProps } from "@chakra-ui/theme-tools";
import { Global } from "@emotion/react";
import type { BorderRadiusEnum, CustomThemeOverride, CustomColorPalette } from "svix";
import tinycolor from "tinycolor2";
import { load as loadWebFont } from "webfontloader";

import baseTheme from "../theme";

interface IThemeProviderProps {
  darkMode: boolean;
  baseFontSize?: number;
  customFontFamily?: string;
  customFontFamilyUrl?: string;
  primaryColorOverride?: string;
  primaryLightOverride?: string;
  primaryDarkOverride?: string;
  paletteLight?: CustomColorPalette;
  paletteDark?: CustomColorPalette;
  themeOverrides?: CustomThemeOverride;
}

function removeEmptyValues(obj?: Record<string, any | null>) {
  return obj
    ? Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
    : undefined;
}

function ColorModeSync(props: IThemeProviderProps) {
  const { darkMode } = props;
  const { colorMode, toggleColorMode } = useColorMode();

  React.useEffect(() => {
    // Keeps `colorMode` in sync with the app's "dark mode," which can be
    // effected by the user-controlled setting (saved to redux), or a query param.
    if ((darkMode && colorMode === "light") || (!darkMode && colorMode === "dark")) {
      toggleColorMode();
    }
  }, [colorMode, darkMode, toggleColorMode]);
  return null;
}

type IGetThemeOptions = Omit<IThemeProviderProps, "darkMode" | "customFontFamilyUrl">;

function getTheme(options: IGetThemeOptions): Record<any, any> {
  const {
    customFontFamily,
    baseFontSize,
    paletteDark = {},
    paletteLight = {},
    primaryColorOverride,
    primaryLightOverride,
    primaryDarkOverride,
    themeOverrides,
  } = options;

  let theme = extendTheme({
    // This is a workaround since Chakra doesn't easily support custom theme colors based on the color mode
    // https://github.com/chakra-ui/chakra-ui/issues/3971
    colors: {
      brand: {
        50: "var(--brand-50)",
        100: "var(--brand-100)",
        200: "var(--brand-200)",
        300: "var(--brand-300)",
        400: "var(--brand-400)",
        500: "var(--brand-500)",
        600: "var(--brand-600)",
        700: "var(--brand-700)",
        800: "var(--brand-800)",
        900: "var(--brand-900)",
      },
      button: {
        primary: "var(--button-primary)",
        hover: "var(--button-primary-hover)",
        outlineHover: "var(--button-outline-hover)",
      },
      navigation: {
        accent: "var(--navigation-accent)",
      },
    },
    radii: getRadiusConfig(themeOverrides),
    ...(customFontFamily && {
      fonts: {
        heading: `"${customFontFamily}", ${baseTheme.fonts.heading}`,
        body: `"${customFontFamily}", ${baseTheme.fonts.body}`,
      },
    }),
    styles: {
      global: (props: StyleFunctionProps) => {
        const modePrimaryColor =
          mode(primaryLightOverride, primaryDarkOverride)(props) ??
          primaryColorOverride ??
          (props.theme.colors.blue[500] as string);

        const buttonPrimary =
          mode(paletteLight.buttonPrimary, paletteDark.buttonPrimary)(props) ??
          (modePrimaryColor as string);

        return {
          ...(modePrimaryColor && {
            ":root": {
              "--brand-50": tinycolor(modePrimaryColor).lighten(30).toHexString(),
              "--brand-100": tinycolor(modePrimaryColor).lighten(20).toHexString(),
              "--brand-200": tinycolor(modePrimaryColor).lighten(15).toHexString(),
              "--brand-300": tinycolor(modePrimaryColor).lighten(10).toHexString(),
              "--brand-400": tinycolor(modePrimaryColor).lighten(5).toHexString(),
              "--brand-500": modePrimaryColor,
              "--brand-600": tinycolor(modePrimaryColor).darken(7.5).toHexString(),
              "--brand-700": tinycolor(modePrimaryColor).darken(12.5).toHexString(),
              "--brand-800": tinycolor(modePrimaryColor).darken(17.5).toHexString(),
              "--brand-900": tinycolor(modePrimaryColor).darken(27.5).toHexString(),
              "--button-primary": buttonPrimary,
              "--button-primary-hover": tinycolor(modePrimaryColor)
                .darken(10)
                .toHexString(),
              "--button-outline-hover": tinycolor(buttonPrimary)
                .lighten(40)
                .toHexString(),
              "--navigation-accent":
                mode(
                  paletteLight.navigationAccent,
                  paletteDark.navigationAccent
                )(props) ?? modePrimaryColor,
            },
          }),
          body: {
            "--chakra-colors-interactive-accent": mode(
              paletteLight.interactiveAccent,
              paletteDark.interactiveAccent
            )(props),
            "--chakra-colors-interactive-accentHover": mode(
              paletteLight.interactiveAccent &&
                tinycolor(paletteLight.interactiveAccent).lighten(15).toHexString(),
              paletteDark.interactiveAccent &&
                tinycolor(paletteDark.interactiveAccent).darken(15).toHexString()
            )(props),
            "--chakra-colors-background-primary": mode(
              paletteLight.backgroundPrimary,
              paletteDark.backgroundPrimary
            )(props),
            "--chakra-colors-background-secondary": mode(
              paletteLight.backgroundSecondary,
              paletteDark.backgroundSecondary
            )(props),
            "--chakra-colors-background-hover": mode(
              paletteLight.backgroundHover,
              paletteDark.backgroundHover
            )(props),
            "--chakra-colors-text-primary": mode(
              paletteLight.textPrimary,
              paletteDark.textPrimary
            )(props),
            "--chakra-colors-text-heading": mode(
              paletteLight.textPrimary,
              paletteDark.textPrimary
            )(props),
            "--color-text-danger": mode(
              paletteLight.textDanger,
              paletteDark.textDanger
            )(props),
            "--chakra-colors-text-muted": mode(
              paletteLight.textPrimary &&
                tinycolor(paletteLight.textPrimary).lighten(10).toHexString(),
              paletteDark.textPrimary &&
                tinycolor(paletteDark.textPrimary).darken(10).toHexString()
            )(props),
          },
        };
      },
    },
  });

  theme = extendTheme(theme, baseTheme);

  if (baseFontSize !== undefined) {
    theme = extendTheme(theme, {
      styles: {
        global: {
          html: {
            fontSize: `${baseFontSize}px`,
          },
        },
      },
    });
  }

  return theme;
}

const borderRadii = {
  none: "0",
  sm: "0.250rem",
  md: "0.375rem",
  lg: "0.75rem",
  full: "30px",
};

const getBorderRadiusFromEnum = (borderRadius?: BorderRadiusEnum) => {
  return borderRadius ? borderRadii[borderRadius] : undefined;
};

const getRadiusConfig = (themeOverrides?: CustomThemeOverride) => {
  return {
    button: getBorderRadiusFromEnum(themeOverrides?.borderRadius?.button),
    input: getBorderRadiusFromEnum(themeOverrides?.borderRadius?.input),
    card: getBorderRadiusFromEnum(themeOverrides?.borderRadius?.card),
  };
};

const Fonts = (props: { fontName: string; fontUrl: string }) => (
  <Global
    styles={`
        @font-face {
          font-family: '${props.fontName}';
          font-display: swap;
          src: url('${props.fontUrl}') format('woff2');
        }
      `}
  />
);

export function AppPortalThemeProvider(
  props: React.PropsWithChildren<IThemeProviderProps>
) {
  const { paletteDark, paletteLight, customFontFamily, customFontFamilyUrl } = props;
  const chakraTheme = React.useMemo(
    () =>
      getTheme({
        ...props,
        paletteDark: removeEmptyValues(paletteDark),
        paletteLight: removeEmptyValues(paletteLight),
      }),
    [paletteDark, paletteLight] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    if (customFontFamily && customFontFamily !== "Custom") {
      loadWebFont({
        google: {
          families: [customFontFamily],
        },
      });
    }
  }, [customFontFamily]);

  return (
    <>
      <ChakraProvider theme={chakraTheme}>
        <CSSReset />
        {customFontFamily === "Custom" && customFontFamilyUrl && (
          <Fonts fontName={customFontFamily} fontUrl={customFontFamilyUrl} />
        )}
        <ColorModeSync {...props} />
        {props.children}
      </ChakraProvider>
    </>
  );
}

interface IDashboardThemeProviderProps {
  darkMode: boolean;
  primaryColor?: string;
}

export function DashboardThemeProvider(
  props: React.PropsWithChildren<IDashboardThemeProviderProps>
) {
  const chakraTheme = getTheme({
    primaryColorOverride: props.primaryColor,
  });

  return (
    <>
      <ChakraProvider theme={chakraTheme}>
        <CSSReset />
        <ColorModeSync darkMode={props.darkMode} />
        {props.children}
      </ChakraProvider>
    </>
  );
}
