import { useEffect } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Checkbox,
  Button as ChakraButton,
  Collapse,
  Divider,
  Flex,
  FormLabel,
  Stack,
  Heading,
  HStack,
  Skeleton,
  Text,
  Tooltip,
  useBoolean,
  Image,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  MenuDivider,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ArrowForwardIos, Info, ExpandMore } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { EndpointIn } from "svix";
import { EnvironmentSettingsApi, TransformationTemplateApi } from "svix/dist/openapi";
import * as yup from "yup";

import { setErrors } from "@svix/common/formUtils";
import { generateSvixPlayRequestUrl } from "@svix/common/play";
import { capitalize } from "@svix/common/utils";
import Button from "@svix/common/widgets/Button";
import EventsList from "@svix/common/widgets/EventsList";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import TextArea from "@svix/common/widgets/form/TextArea";
import TextField from "@svix/common/widgets/form/TextField";
import StyledLink from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAllEventTypes, useAppQuery } from "src/hooks/api";
import useFeatureFlag from "src/hooks/featureFlag";
import { useAppSelector } from "src/hooks/store";
import webhookIcon from "src/img/webhook.svg";
import { isEE } from "src/utils";
import { SinkButtons, SinkCreateForm } from "./Sinks";
import TransformationTemplateForm from "./TemplateForm";
import { MAX_CHANNELS } from "../Endpoint/properties/Channels";
import ChannelsAutocomplete from "../Endpoint/properties/ChannelsAutosuggest";

const schema = yup.object().shape({
  url: yup.string().url("Invalid URL"),
  description: yup.string(),
  filterTypes: yup.mixed(),
  customHeaders: yup.object().nullable(),
  channels: yup.array().of(yup.string()).max(MAX_CHANNELS).nullable(),
  rateLimit: yup
    .number()
    .nullable()
    .transform((_, val) => (val !== "" && val !== null ? Number(val) : null)),
});

const DEFAULT_VALUES = {
  url: "",
  version: 1,
  filterTypes: [] as string[],
  customHeaders: undefined,
  rateLimit: undefined,
};

export default function EndpointCreateScreen() {
  const router = useHistory();
  const { integrationId = "http" } = useParams<{ integrationId?: string }>();
  const { data: templateList, isLoading } = useAppQuery(
    ["transformationTemplates"],
    async () => {
      const svix = getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      return api.v1TransformationTemplateList({ limit: 250 });
    },
    {
      enabled: !isEE,
    }
  );

  const selectedSink = SinkButtons.find((sb) => sb.type.toLowerCase() === integrationId);
  const template = templateList?.data.find((t) => t.id === integrationId);

  useEffect(() => {
    if (!isLoading && !template && !selectedSink) {
      // Integration not found, redirect to http endpoint
      router.push(routeResolver.getRoute("endpoints.new"));
    }
  }, [router, template, selectedSink, integrationId, isLoading]);

  return (
    <>
      <MetaTitle path={["New Endpoint"]} />

      <PageToolbar>
        <HStack maxW="50em" justifyContent="space-between" w="100%">
          <Breadcrumbs>
            <BreadcrumbItem to={routeResolver.getRoute("home")}>Endpoints</BreadcrumbItem>
            <BreadcrumbItem>New Endpoint</BreadcrumbItem>
          </Breadcrumbs>
          <EndpointTypeSwitcher />
        </HStack>
      </PageToolbar>

      <Stack>
        {integrationId === "http" && <EndpointCreateForm />}
        {selectedSink && <SinkCreateForm type={selectedSink.type} />}
        {template && <TransformationTemplateForm template={template} />}
      </Stack>
    </>
  );
}

function EndpointTypeSwitcher() {
  const router = useHistory();
  const { integrationId = "http" } = useParams<{ integrationId?: string }>();
  const sinksEnabled = useFeatureFlag("sinks");

  const { data: templateList, isLoading } = useAppQuery(
    ["transformationTemplates"],
    async () => {
      const svix = getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      return api.v1TransformationTemplateList({ limit: 250 });
    },
    {
      enabled: !isEE,
    }
  );

  const selectedSink = [
    ...SinkButtons,
    {
      type: "http",
      icon: webhookIcon,
      name: "HTTP Endpoint",
      description: "Receive webhooks over HTTP.",
    },
  ].find((sb) => sb.type.toLowerCase() === integrationId);
  const selectedTemplate = templateList?.data.find((t) => t.id === integrationId);

  const showOptions = sinksEnabled || (templateList && templateList.data.length > 0);

  return (
    <>
      {showOptions ? (
        <Skeleton isLoaded={!isLoading}>
          <Menu placement="bottom-end">
            <MenuButton as={ChakraButton} py={5}>
              <Flex alignItems="center">
                <Box mr={3}>
                  <EndpointTypeListItem
                    icon={(selectedTemplate?.logo ?? selectedSink?.icon)!}
                    name={(selectedTemplate?.name ?? selectedSink?.name)!}
                  />
                </Box>
                <ExpandMore opacity={0.6} />
              </Flex>
            </MenuButton>
            <MenuList overflowY="scroll">
              <MenuItem
                value="http"
                onClick={() => router.push(routeResolver.getRoute("endpoints.new"))}
              >
                <EndpointTypeListItem
                  icon={webhookIcon}
                  name="HTTP Endpoint"
                  description="Receive webhooks over HTTP."
                />
              </MenuItem>
              {sinksEnabled && (
                <>
                  <MenuDivider />
                  {SinkButtons.map((sb) => (
                    <MenuItem
                      value={sb.type}
                      key={sb.type}
                      onClick={() =>
                        router.push(
                          routeResolver.getRoute("endpoints.new._id", {
                            integrationId: sb.type,
                          })
                        )
                      }
                    >
                      <EndpointTypeListItem
                        icon={sb.icon}
                        name={sb.name}
                        description={sb.description}
                      />
                    </MenuItem>
                  ))}
                </>
              )}
              {templateList && templateList.data.length > 0 && (
                <>
                  <MenuDivider />
                  {templateList.data.map((t) => (
                    <MenuItem
                      value={t.id}
                      key={t.id}
                      onClick={() =>
                        router.push(
                          routeResolver.getRoute("endpoints.new._id", {
                            integrationId: t.id,
                          })
                        )
                      }
                    >
                      <EndpointTypeListItem
                        icon={t.logo}
                        name={t.name}
                        description={t.description}
                      />
                    </MenuItem>
                  ))}
                </>
              )}
            </MenuList>
          </Menu>
        </Skeleton>
      ) : null}
    </>
  );
}

interface EndpointTypeListItemProps {
  icon: string;
  name: string;
  description?: string;
}

function EndpointTypeListItem(props: EndpointTypeListItemProps) {
  const { name, icon, description } = props;
  return (
    <HStack spacing={4}>
      <Image src={icon} alt={name} width="24px" height="24px" />
      <Stack justifyContent="center" minH="32px" spacing={0}>
        <Text textAlign="left" fontWeight="semibold">
          {name}
        </Text>
        {description && (
          <Text textAlign="left" fontSize="sm" color="text.secondary">
            {description}
          </Text>
        )}
      </Stack>
    </HStack>
  );
}

function EndpointCreateForm() {
  const defaultValues = DEFAULT_VALUES as EndpointIn;
  const user = useAppSelector((state) => state.auth.user)!;
  const { stringsOverrides, hideEventTypes, hideUseSvixPlay } = useAppSelector(
    (state) => state.embedConfig
  );
  const [hasEndpointRateLimit, setHasEndpointRateLimit] = useBoolean();
  const history = useHistory();
  const formCtx = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { data: orgSettings } = useAppQuery(
    ["orgSettings"],
    async () => {
      const sv = getSvix();
      const config = sv._configuration;
      const api = new EnvironmentSettingsApi(config);
      return api.v1EnvironmentGetSettings({});
    },
    {
      // since org settings should not likely to change frequently, don't refetch
      // on re-mount
      staleTime: Infinity,
    }
  );

  const { data: availableEvents } = useAllEventTypes();

  function useSvixPlay(e: any) {
    e.preventDefault();
    formCtx.setValue("url", generateSvixPlayRequestUrl(), {
      shouldDirty: true,
    });
  }

  async function onAddEndpoint(form: EndpointIn) {
    const dh = getSvix();
    if (form.filterTypes && form.filterTypes.length === 0) {
      delete form["filterTypes"];
    }
    if (form.channels && form.channels.length === 0) {
      delete form["channels"];
    }

    if (!hasEndpointRateLimit) {
      delete form["rateLimit"];
    }

    try {
      const endpoint = await dh.endpoint.create(user.app.id, form);
      history.push(routeResolver.getRoute("endpoints._id", { endpId: endpoint.id }));
    } catch (e) {
      setErrors(formCtx.setError, e.body);
    }
  }

  const eePlayHelper = (
    <span>
      Configure an endpoint or{" "}
      <StyledLink
        type="button"
        as="button"
        textDecoration="underline"
        fontWeight="medium"
        onClick={useSvixPlay}
        mr={1}
      >
        <span>add a test URL</span>
      </StyledLink>
      <Tooltip
        label="This will generate a unique endpoint to receive and inspect incoming webhooks."
        hasArrow
      >
        <Info fontSize="small" />
      </Tooltip>
    </span>
  );

  const cloudPlayHelper = (
    <span>
      Configure an endpoint or test{" "}
      <StyledLink
        type="button"
        as="button"
        textDecoration="underline"
        fontWeight="medium"
        onClick={useSvixPlay}
        mr={1}
      >
        <span>with Svix Play</span>
      </StyledLink>
      <Tooltip
        label="Svix Play instantly gives you a unique endpoint to receive and inspect incoming webhooks."
        hasArrow
      >
        <Info fontSize="small" />
      </Tooltip>
    </span>
  );

  return (
    <Form onSubmit={onAddEndpoint} {...formCtx}>
      <Box maxW="50em">
        <Stack spacing={5}>
          <TextField
            autoFocus
            control={formCtx.control}
            name="url"
            label="Endpoint URL"
            type="url"
            isRequired
            placeholder="e.g. https://www.example.com/webhook"
            helperText={!hideUseSvixPlay && (isEE ? eePlayHelper : cloudPlayHelper)}
          />
          <TextArea
            name="description"
            control={formCtx.control}
            label="Description"
            placeholder="An optional description of what this endpoint is used for."
          />
          <Stack spacing={5}>
            {!hideEventTypes && (
              <EventsList
                availableEvents={availableEvents?.data || []}
                control={formCtx.control}
                name="filterTypes"
                label={
                  <Flex alignItems="center" justifyContent="space-between">
                    <span>Subscribe to events</span>
                    <StyledLink
                      fontSize="sm"
                      display="flex"
                      alignItems="center"
                      color="interactive.accent"
                      to={routeResolver.getRoute("event-types")}
                    >
                      Event Catalog
                      <ArrowForwardIos style={{ fontSize: 15, marginLeft: 4 }} />
                    </StyledLink>
                  </Flex>
                }
                emptyState="Receiving all events."
              />
            )}
            {orgSettings?.enableChannels && (
              <ChannelsAutocomplete
                label={capitalize(stringsOverrides.channelsMany)}
                name="channels"
                control={formCtx.control}
                helperText={stringsOverrides.channelsHelp}
              />
            )}
          </Stack>
          <Accordion allowToggle borderTopWidth={0} my={4} w="100%">
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Heading size="sm" as="div" flex="1" textAlign="left">
                    Advanced Configuration
                  </Heading>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Stack spacing={5}>
                  <Box my={2}>
                    <Checkbox
                      name="enableEndpointRatelimit"
                      isChecked={hasEndpointRateLimit}
                      onChange={setHasEndpointRateLimit.toggle}
                    >
                      Enable endpoint rate limiting (throttling)?
                    </Checkbox>
                    <Collapse in={hasEndpointRateLimit} animateOpacity>
                      <Box mt={3}>
                        <TextField
                          control={formCtx.control}
                          label="Rate Limit (per second)"
                          helperText="Message delivery will be throttled to this rate."
                          name="rateLimit"
                          placeholder="0"
                          type="number"
                          maxW="16em"
                        />
                      </Box>
                    </Collapse>
                  </Box>
                  <Box>
                    <FormLabel>Custom Headers</FormLabel>
                    <Text size="sm">
                      If you want to configure custom headers for this endpoint, you can
                      do so from the endpoint details page after it's created.
                    </Text>
                  </Box>
                </Stack>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
          <input name="version" type="hidden" value={DEFAULT_VALUES.version} />
        </Stack>
        <GeneralFormErrors />
        <Divider mt={4} />

        <HStack mt={4} spacing={4}>
          <Button colorScheme="gray" as={Link} to={routeResolver.getRoute("endpoints")}>
            Cancel
          </Button>

          <SubmitButton isLoading={formCtx.formState.isSubmitting}>Create</SubmitButton>
        </HStack>
      </Box>
    </Form>
  );
}
