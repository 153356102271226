import { useState, useEffect } from "react";

export default function useDelayedRender(enterDelay: number) {
  const [rendered, setRendered] = useState(false);

  useEffect(() => {
    setRendered(false);

    const timeoutId = window.setTimeout(() => {
      setRendered(true);
    }, enterDelay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [enterDelay]);

  return {
    rendered,
  };
}
