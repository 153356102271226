import { FieldValues, UseFormSetError } from "react-hook-form";

interface IFastApiError {
  loc: string[];
  msg: string;
  type: string;
}
interface IFastApiErrors {
  code?: string;
  detail: IFastApiError[] | string | undefined;
}

export const FORM_ERROR = "FORM_ERROR";
export type FormErrors = { [FORM_ERROR]?: never };

export function setErrors<T extends FieldValues>(
  setError: UseFormSetError<T>,
  fastApiRes: IFastApiErrors | undefined,
  translateMsg?: (type: string | undefined, path: string) => string
): void {
  if (!fastApiRes) {
    return;
  }

  if (typeof fastApiRes.detail === "string") {
    const { code, detail } = fastApiRes;
    (setError as any)(FORM_ERROR, {
      type: code,
      message: translateMsg ? translateMsg(code, detail) : detail,
    });
  } else {
    fastApiRes.detail?.forEach((apiErr: IFastApiError) => {
      apiErr.loc.shift(); // Remove 'body'
      (setError as any)(FORM_ERROR, {
        type: apiErr.type,
        message: translateMsg ? translateMsg(apiErr.type, apiErr.msg) : apiErr.msg,
      });
    });
  }
}

export function stripEmptyFields<T extends Record<string, any>>(data: T): T {
  Object.keys(data).forEach((key) => {
    if (data[key] === "" || data[key] === null) {
      delete data[key];
    }
    if (!!data[key] && typeof data[key] === "object") {
      stripEmptyFields(data[key]);
    }
  });
  return data;
}

export function downloadEncodedFile(
  encodedData: any,
  filename: string,
  mimetype: string
): void {
  const dataUrl = `data:${mimetype},${encodedData}`;

  const tempLink = document.createElement("a");
  tempLink.style.display = "none";
  tempLink.href = dataUrl;
  tempLink.setAttribute("download", filename);
  tempLink.setAttribute("target", "_blank");
  document.body.appendChild(tempLink);
  tempLink.click();

  window.setTimeout(function () {
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(dataUrl);
  }, 200);
}

export function downloadAsJson(data: any, filename: string): void {
  downloadEncodedFile(
    encodeURIComponent(JSON.stringify(data)),
    filename,
    "text/json;charset=utf-8"
  );
}
