import { Code } from "@chakra-ui/react";
import { MessageOut } from "svix";

import { formatDateTime, humanize } from "@svix/common/utils";
import RadioButtons from "@svix/common/widgets/form/RadioButtons";

export type IReplayType = "single" | "all-failed-since" | "all-missing-since";

interface IReplayRadioProps {
  isDisabled?: boolean;
  control: any;
  msg: MessageOut;
  name: string;
}

export default function ReplayRadio(props: IReplayRadioProps) {
  const { msg, ...rest } = props;

  const buttons = Object.freeze([
    {
      title: (
        <>
          Resend this message (<Code>{humanize(msg.id)}</Code>)
        </>
      ),
      description: "Replay just this message",
      value: "single" as IReplayType,
    },
    {
      title: "Resend all failed messages since",
      description: `Resend all failed messages since ${formatDateTime(msg.timestamp)}`,
      value: "all-failed-since" as IReplayType,
    },
    {
      title: "Replay all missing messages since",
      description: `Replay messages never attempted for this endpoint since ${formatDateTime(
        msg.timestamp
      )}`,
      value: "all-missing-since" as IReplayType,
    },
  ]);

  return <RadioButtons<IReplayType> buttons={buttons} {...rest} />;
}
