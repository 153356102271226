import { useCallback, useEffect, useState } from "react";

import { routeResolver } from "src/App";
import { getPopupWindowFeatures } from "./utils";

// FIXME: This is a copy-paste of the inngest integration, we should refactor to use a shared util
export const useWindmillIntegration = (onWebhookUrlReceived: (url: string) => void) => {
  const [externalPopup, setExternalPopup] = useState<Window | null>(null);
  const [error, setError] = useState<string | null>(null);

  const redirectUri = `https://redirectmeto.com/${
    window.location.origin
  }${routeResolver.getRoute("oauth.windmill.webhook")}`;

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }

        const searchParams = new URL(currentUrl).searchParams;
        const windmillUrl = searchParams.get("webhook_url");
        const authorizeError = searchParams.get("error");

        if (windmillUrl || authorizeError) {
          if (windmillUrl) {
            onWebhookUrlReceived(windmillUrl);
          }

          if (authorizeError) {
            setError("Failed to connect to Windmill. Please try again.");
          }
          externalPopup.close();
          setExternalPopup(null);
          clearInterval(timer);
        }
      } catch (e) {
        // Ignore error. Keep polling until user is redirected.
      }
    }, 100);
  }, [externalPopup, setError, onWebhookUrlReceived]);

  const onConnect = useCallback(() => {
    const popup = window.open(
      getWindmillAppPortalConnectionUrl(redirectUri, getCustomerDomain()),
      "_blank",
      getPopupWindowFeatures()
    );
    setExternalPopup(popup);
  }, [redirectUri]);

  return {
    onConnect,
    error,
  };
};

const getCustomerDomain = () => {
  // Firefox doesn't support ancestorOrigins yet
  const ancestorOrigins = window.location.ancestorOrigins as DOMStringList | undefined;
  const parentUrl = ancestorOrigins?.[0];

  if (parentUrl) {
    const { hostname } = new URL(parentUrl);
    return hostname;
  }

  return "webhook-integration";
};

export const getWindmillAppPortalConnectionUrl = (
  redirectUri: string,
  customerDomain: string
) => {
  const encodedCustomerDomain = encodeURIComponent(customerDomain);
  return `https://app.windmill.dev/svix/create-webhook?domain=${encodedCustomerDomain}&redirect_uri=${redirectUri}`;
};
